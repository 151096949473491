import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { get, isSandbox, requestV2, successfulLoginHandler } from "../utils/io";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 350,
  },
  item: {
    margin: theme.spacing(1, 0),
  },
}));

function Login() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState(false);
  const [useSso, setUseSso] = useState(true);
  const redirect = new URLSearchParams(location.search).get("redirect");

  const snackbarError = {
    message: "Unable to login, please try again.",
    variant: "error",
  };

  const onLoginClicked = async () => {
    setLoading(true);

    try {
      const res = await requestV2(
        "post",
        "/auth/token",
        {
          noAuth: true,
          data: { password, username },
        },
        false
      );
      await successfulLoginHandler(res.data);
      const path = isSandbox() ? "/get-started" : "/hubs";
      history.push(redirect || path);
    } catch (err) {
      enqueueSnackbar(snackbarError);
      setLoading(false);
    }
  };

  const onLoginWithSsoClicked = async () => {
    if (!username) {
      setUsernameErr(true);
      return;
    }

    const atSignIdx = username.indexOf("@");
    if (atSignIdx === -1) {
      setUseSso(false);
      return;
    }

    setLoading(true);
    const redirectPath =
      new URLSearchParams(location.search).get("redirect") || "/";

    try {
      // TODO add a random value into state and store in sessionStorage for CSRF
      // stateVerifier = crypto.getRandomValues
      const res = await get(
        "/auth/sso/idpurl",
        {
          username,
          redirect_uri: `${window.origin}/auth/oauth-token`,
          state: JSON.stringify({ redirect: redirectPath }),
        },
        { noAuth: true }
      );
      if (res.status === 200) {
        window.location = encodeURI(res.data.idpUrl);
      } else {
        setUseSso(false);
      }
    } catch (err) {
      enqueueSnackbar(snackbarError);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    return useSso ? onLoginWithSsoClicked() : onLoginClicked();
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #ccc",
        borderRadius: "4px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4),
      })}
    >
      <form className={classes.form} onSubmit={onSubmit}>
        <h3>sign in</h3>
        <TextField
          autoFocus
          className={classes.item}
          id="outlined-required"
          label="Email/Username"
          onChange={(e) => setUsername(e.target.value)}
          required
          value={username}
          error={usernameErr}
          variant="outlined"
        />

        {!useSso && (
          <>
            <TextField
              autoComplete="current-password"
              className={classes.item}
              id="outlined-password-input"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
              type="password"
              value={password}
              variant="outlined"
              autoFocus
            />
            <Link mb="10px" href="/password-reset">
              Forgot password?
            </Link>
          </>
        )}

        <LoadingButton loading={loading} variant="contained" type="submit">
          {useSso ? "Continue" : "Login"}
        </LoadingButton>
        <Box mt="10px">
          New to Stel? <Link href="new-organization">Sign up.</Link>
        </Box>
      </form>
    </Box>
  );
}

export default Login;
